<template>
  <div class="login">
    <p>{{ $t('views.login.welcome_note') }}</p>
    <b-jumbotron>
      <b-row>
        <b-col lg="3">
          <img class="img-fluid" src="/logo.webp" alt="SCC Logo"/>
        </b-col>
        <b-col>
          <b-form @submit="login">
            <template v-if="!use_oic">
              <b-form-group label-for="username" :label="$t('system.kit_account')">
                <b-form-input id="username" v-model="username" autofocus type="text"/>
              </b-form-group>
              <b-form-group label-for="password" :label="$t('system.password')">
                <b-form-input id="password" v-model="password" type="password"/>
              </b-form-group>
              <b-form-checkbox v-model="stay_logged_in" :unchecked-value="false" :value="true" class="mb-3">
                {{ $t('views.login.stay_logged_in') }}
              </b-form-checkbox>
              <b-button type="submit" variant="outline-primary">
                <netvs-icon icon="sign_in"/>
                {{ $t('system.login') }}
              </b-button>
            </template>
            <template v-else>
              <b-alert variant="info" show>
                {{ $t('views.login.2fa_required') }}
              </b-alert>
              <b-form-checkbox v-model="stay_logged_in" :unchecked-value="false" :value="true" class="mb-3">
                {{ $t('views.login.stay_logged_in') }}
              </b-form-checkbox>
              <b-button type="submit" variant="outline-primary" @click="login_token = ''">
                <netvs-icon icon="sign_in_oidc"/>
                {{ $t('system.login') }}
              </b-button>
            </template>
          </b-form>
          <b-form @submit="login" v-if="$store.state.expert">
            <hr/>
            <h3>{{ $t('views.login.use_api_token') }}
              <NetvsExpertMarker/>
            </h3>
            <b-form-input v-model="login_token" type="password"/>
            <b-button type="submit" class="mt-2" variant="outline-primary">
              <netvs-icon icon="sign_in"/>
              {{ $t('system.login') }}
            </b-button>
          </b-form>
        </b-col>
      </b-row>
    </b-jumbotron>
  </div>
</template>

<script>
import LoginService from '@/api-services/login.service'
import router from '@/router'
import config from '@/../netvs.config'
import NetvsExpertMarker from '@/components/NETVSExpertMarker.vue'

export default {
  name: 'net_suite_login',
  components: { NetvsExpertMarker },
  data() {
    return {
      username: '',
      password: '',
      prevRoute: null,
      use_oic: config.ENABLE_OIC,
      stay_logged_in: false,
      login_token: null
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.path !== '/login') {
        vm.prevRoute = from
        if (vm.use_oic) {
          window.localStorage.setItem('oic_redir_after_login', from.path)
        }
      }
    })
  },
  methods: {
    redir: async function () {
      let redir = self.prevRoute ? self.prevRoute.path : this.$store.state.default_login_page
      if (redir === '/dnsvs') {
        redir = '/dnsvs/bcds'
      }
      if (!this.$store.state.spec_ready) {
        redir = this.$store.state.default_login_page
      }
      await router.push(redir)
    },
    login: async function (ev) {
      ev.preventDefault()
      if (this.$store.state.user) {
        await this.redir()
        return
      }
      const self = this
      if (this.login_token && this.login_token !== '' && this.$store.state.expert) {
        self.$store.commit('login', {
          token: { token: this.login_token }
        })
        await self.$store.dispatch('refresh_session_info')
        await this.redir()
        return
      }
      const response = await LoginService.login(this.username, this.password, this.stay_logged_in)
      if (response.data.login) {
        window.location = response.data.login
      }
    }
  }
}
</script>
